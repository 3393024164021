<template>
  <div>
    <SearchByBarcode :urlGet="urlGet" :params="{ statusPatrimony }" />
    <b-row>
      <b-col sm="12">
        <div class="div-search">
          <Button _key="btnAddSearch" type="primary" title="Pesquisar Manual" classIcon="fa-regular fa-magnifying-glass"
            size="small" :clicked="executeSearch" />
        </div>
      </b-col>
    </b-row>
    <Modal title="Pesquisar" :width="1200" :height="750" v-if="showModal('searchProduct')">
      <Alert type="danger" v-show="showAlert">
        <span> {{ message }}</span>
      </Alert>
      <SearchProduct :types="'0,1,2,0,5'" :status="1" :statusPatrimony="statusPatrimony" :showPrice="false"
        :onlyPatrimony="manageByPatrimony" :showAvailability="false" :periodRent="selectedPeriod.period.fullPeriod"
        :addProduct="verifyIfCanAdd">
      </SearchProduct>
    </Modal>
  </div>
</template>
<script>
import SearchByBarcode from "../search-product/SearchByBarcode.vue";
import InputText from "@nixweb/nixloc-ui/src/component/forms/InputText";
import Molded from "@nixweb/nixloc-ui/src/component/layout/Molded";
import Alert from "@nixweb/nixloc-ui/src/component/layout/Alert";
import Modal from "@nixweb/nixloc-ui/src/component/forms/Modal";
import Button from "@nixweb/nixloc-ui/src/component/forms/Button";
import ScrollBar from "@nixweb/nixloc-ui/src/component/layout/ScrollBar.vue";
import Loading from "@nixweb/nixloc-ui/src/component/shared/Loading.vue";
import RadioGroup from "@nixweb/nixloc-ui/src/component/forms/RadioGroup.vue";

import SearchProduct from "../search-product/SearchProduct.vue";

import { mapActions, mapMutations, mapState, mapGetters } from "vuex";

export default {
  components: {
    InputText,
    Molded,
    Alert,
    Modal,
    Button,
    ScrollBar,
    Loading,
    SearchProduct,
    RadioGroup,
    SearchByBarcode,
  },
  name: "ProductAdd",
  props: {
    rentId: String,
    statusPatrimony: Number,
  },
  data() {
    return {
      urlGet: "/api/v1/moviment/generate-moviment/get-by-barcode",
      barCode: "",
      showAlert: false,
      message: "",
      duplicate: [],
      selectedPeriod: {},
      loading: false,
    };
  },
  computed: {
    ...mapState("generateMoviment", ["productSelected", "type"]),
    ...mapState("generic", ["modal", "event"]),
    ...mapGetters("generateMoviment", [
      "verifyIfExistSelected",
      "verifyIfExistRented",
      "verifyIfExistPatrimony",
    ]),
    ...mapGetters("generic", ["showModal"]),
    ...mapState("user", ["userLogged"]),
    manageByPatrimony() {
      return this.userLogged.parameterStock.manageByPatrimony;
    },
  },
  methods: {
    ...mapActions("generic", ["getApi"]),
    ...mapMutations("generateMoviment", ["addProductSelected"]),
    ...mapMutations("validation", ["addValidation"]),
    ...mapMutations("generic", ["openModal", "hideModal", "removeLoading"]),
    addWithOpticalReader() { },
    verifyIfCanAdd(item) {
      if (this.type == "output") this.executeValidationOutput(item);
      if (this.type == "return") this.executeValidationReturnOrRenewed(item);
      if (this.type == "renewed") this.executeValidationReturnOrRenewed(item);
      let self = this;
      setTimeout(function () {
        self.removeLoading([item.id + "p", item.id]);
      }, 250);
    },
    executeValidationOutput(item) {
      if (this.verifyIfExistSelected(item)) {
        this.showNotification("Produto já está na lista!");
      } else {
        this.addProduct(item);
      }
    },
    executeValidationReturnOrRenewed(item) {
      if (this.manageByPatrimony) {
        if (!this.verifyIfExistPatrimony(item) && !item.allowWithoutPatrimony) {
          this.showNotification("Produto  não faz parte da lista para ser retornado!");
          return;
        }
        if (!this.verifyIfExistRented(item) && item.allowWithoutPatrimony) {
          this.showNotification("Produto não faz parte da lista para ser retornado!");
          return;
        }
      }
      if (this.verifyIfExistSelected(item)) {
        this.showNotification("Produto já está na lista!");
        return;
      }
      this.addProduct(item);
    },
    addProduct(item) {
      let product = JSON.parse(JSON.stringify(item));
      product.type = this.type;
      this.addProductSelected(product);
      this.barCode = "";
      this.audio("success");
      this.$toasted.show("Produto adicionado", {
        type: "success",
      });
    },
    audio(type) {
      const success = require("../../../../../public/sound/success.wav");
      const error = require("../../../../../public/sound/error.wav");
      const src = type == "success" ? success : error;
      var sound = new Audio(src);
      sound.play();
    },
    executeSearch() {
      this.openModal("searchProduct");
      this.removeLoading(["btnAddSearch"]);
    },
    showNotification(message) {
      this.showAlert = true;
      this.message = message;
      this.hideMessage();
    },
    hideMessage() {
      let self = this;
      setTimeout(function () {
        self.showAlert = false;
      }, 4000);
    },
  },
  watch: {
    event: {
      handler(event) {
        if (event.name == "selectedPeriodRent") {
          this.selectedPeriod = event.data;
        }

        if (event.name == "executedSearchByBarcode")
          this.verifyIfCanAdd(event.data);
      },
      deep: true,
    },
  },
};
</script>
<style scoped>
.div-bar-code {
  margin-top: 27px;
}

.item-duplicate {
  margin-top: 5px;
  background-color: #f5f5f5;
  border: 1px solid #ddd;
  padding-top: 10px;
  padding-left: 15px;
  padding-right: 15px;
  border-radius: 5px;
  background-color: #fafafc !important;
}

.div-search {
  margin-bottom: 10px;
}
</style>